<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <brand />
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            src="@/assets/images/pages/register-v2.svg"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Đăng ký Tingee 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            Gạch nợ tức thì, vì sao không thử???
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{ invalid }"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <!-- name -->
              <b-form-group
                label="Họ tên"
              >
                <template
                  v-slot:label
                >
                  Họ tên
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="name"
                    name="name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Nguyễn Nam Phong"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- phone -->
              <b-form-group
                label="Số điện thoại"
              >
                <template
                  v-slot:label
                >
                  Số điện thoại
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="phone"
                  vid="phone"
                  rules="required"
                >

                  <b-form-input
                    id="phone"
                    v-model="phone"
                    name="phone"
                    :state="errors.length > 0 ? false : null"
                    placeholder="0868987355"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                label="Địa chỉ email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  vid="email"
                  rules="email"
                >
                  <b-form-input
                    id="email"
                    v-model="email"
                    name="email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="phongnn@tingee.vn"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label="Mật khẩu"
              >
                <template
                  v-slot:label
                >
                  Mật khẩu
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Mật khẩu"
                  vid="password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label="Xác nhận mật khẩu"
              >
                <template
                  v-slot:label
                >
                  Xác nhận mật khẩu
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Xác nhận mật khẩu"
                  vid="passwordConfirm"
                  rules="required|password|confirmed:password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="passwordConfirm"
                      v-model="passwordConfirm"
                      class="form-control-merge"
                      :type="passwordConfirmFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="passwordConfirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordConfirmToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordConfirmVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="agreement"
                  rules="agreement"
                >
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="status"
                    name="checkbox"
                  >
                    Tôi đồng ý với
                    <b-link
                      href="https://drive.google.com/file/d/1s8Mzy7yitiI2Ak46TMgJHhOmu-Ww6Y8H/view"
                      target="_blank"
                    >
                      Chính sách sử dụng
                    </b-link> và
                    <b-link
                      href="https://drive.google.com/file/d/1wBO_8aGk_EOcSUN9NK2H3AfMZfPjU6Q_/view"
                      target="_blank"
                    >
                      Điều khoản bảo mật
                    </b-link>
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid || isLoading"
              >

                <b-spinner
                  v-if="isLoading"
                  small
                />
                {{ isLoading ? 'Đang đăng ký' : 'Đăng ký' }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Bạn đã có tài khoản?</span>
            <b-link :to="{ name: 'auth-login' }">
              <span>&nbsp;Đăng nhập</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BSpinner,
} from 'bootstrap-vue';
import { required, agreement } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt';
import md5 from 'md5';
import { togglePasswordConfirmVisibility } from '@/layouts/mixins/forms';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Brand from '@/layouts/components/Brand.vue';

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,

    BSpinner,
    Brand,
  },
  mixins: [togglePasswordVisibility, togglePasswordConfirmVisibility],
  data() {
    return {

      name: '',
      phone: '',
      email: '',
      password: '',
      passwordConfirm: '',
      status: true,
      // validation
      required,
      agreement,
      isLoading: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordConfirmToggleIcon() {
      return this.passwordConfirmFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.isLoading = true;
          let data = {
            name: this.name,
            phone: this.phone,
            password: md5(this.password),
            role: 'host',
          };
          if (this.email && this.email.length > 0) {
            data = {
              ...data,
              email: this.email,
            };
          }
          useJwt
            .register(data)
            .then(response => {
              this.isLoading = false;
              const { user, accessToken, refreshToken } = response.data;
              useJwt.setToken(accessToken);
              useJwt.setRefreshToken(refreshToken);
              localStorage.setItem(
                'userData',
                JSON.stringify(user),
              );
              this.$ability.update([{
                action: 'manage',
                subject: 'all',
              }]);
              this.$router
                .replace({ name: 'invoice-list' })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Xin chào ${user.name}`,
                      icon: 'UserIcon',
                      variant: 'success',
                      text: `Đăng ký tài khoản thành công`,
                    },
                  });
                });
              this.isLoading = false;
            })
            .catch(error => {
              this.isLoading = false;

              if (error.response && error.response.data && error.response.data.errors) {
                this.$refs.registerForm.setErrors(error.response.data.errors);
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Thông báo',
                    text: 'Lỗi hệ thống. Vui lòng chờ trong giây lát hoặc liên hệ hỗ trợ kỹ thuật để được trợ giúp kịp thời. Hotline liên hệ: 0868987355',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              }
            });
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
